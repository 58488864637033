import React, {createContext, useState} from "react";

// create a attribute context
const AttributeContext = createContext();

// assign values for cogid, stagingFlag, email, and mfa to the attribute context
const Attribute = (props) => {
  const [cogid, setCogid] = useState('');
  const [stagingFlag, setStagingFlag] = useState('');
  const [email, setEmail] = useState('');
  const [mfa, setMfa] = useState('');
  const [userSaved, setUserSaved] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);

  return (
    <AttributeContext.Provider
      value={{
        cogid,
        setCogid,
        stagingFlag,
        setStagingFlag,
        email,
        setEmail,
        mfa,
        setMfa,
        userSaved,
        setUserSaved,
        loggedIn,
        setLoggedIn
      }}
    >
      {props.children}
    </AttributeContext.Provider>
  );
};

export { Attribute, AttributeContext };