import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import logo from "../ipaws-logo_blue-text.png";
import LogoutButton from "../components/universal/LogoutButton";
import {
  Container,
  Navbar,
  NavDropdown,
  Nav,
  Offcanvas,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import LoggedInUser from "../components/universal/LoggedInUser";
import Env from "../env.js";
import { AttributeContext } from "../components/universal/Attributes";

const Layout = (props) => {
  const slug = Env.SLUG;
  const app = slug + "app";
  const mdd = slug + "mdd";
  const mv = slug + "messageviewer";
  const dashboard = slug + "dashboard";
  const contact = slug + "contact";
  const userguide = process.env.REACT_APP_USER_GUIDE_URL;
  const Attributes = useContext(AttributeContext);
  const { mfa } = Attributes;

  // console.log("MFA: " + mfa);
  // console.log(
  //   "MFA === SOFTWARE_TOKEN_MFA: " + mfa === undefined
  //     ? "Disabled"
  //     : mfa === "SOFTWARE_TOKEN_MFA"
  //     ? "Enabled"
  //     : "Disabled"
  // );

  const tooltip = (
    <Tooltip
      id="tooltip"
      hidden={
        mfa === undefined ? false : mfa === "SOFTWARE_TOKEN_MFA" ? true : false
      }
    >
      <strong>Access to applications is disabled.</strong>
      <br />
      Set up MFA to enable.
    </Tooltip>
  );

  return (
    <>
      {["md"].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          expand={expand}
          className="mb-3"
          sticky="top"
        >
          <Container fluid>
            <img
              src={logo}
              href={slug}
              className="logoImg d-inline-block align-top"
              alt="FEMA Logo"
            />
            <Navbar.Brand href={slug}>Alerting Authority ATP</Navbar.Brand>
            {props.loggedIn ? (
              // content to render when loggedIn is true
              <>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                      className="modernFont"
                    >
                      IPAWS Alerting Authority ATP
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Nav.Link href={slug}>Home</Nav.Link>
                      <Nav.Link href={dashboard}>User Dashboard</Nav.Link>
                      <OverlayTrigger
                        placement="left"
                        overlay={tooltip}
                        delayHide={500}
                      >
                        <NavDropdown
                          className={mfa ? "d-block in" : "app-dropdown-red in"}
                          title="Applications"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                        >
                          <NavDropdown.Item href={app} disabled={!mfa}>
                            View All Apps
                          </NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href={mv} disabled={!mfa}>
                            Message Viewer
                          </NavDropdown.Item>
                          <NavDropdown.Item href={mdd} disabled={!mfa}>
                            MDD
                          </NavDropdown.Item>
                        </NavDropdown>
                      </OverlayTrigger>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </>
            ) : (
              // content to render when loggedIn is false
              <>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href={slug}>Login</Nav.Link>
                </Nav>
              </>
            )}
          </Container>
        </Navbar>
      ))}
      <div className="outlet-div">
        <Outlet context={[props.loggedIn]} />
      </div>

      {["md"].map((expand) => (
        <Navbar
          key={expand}
          className="footerBar"
          bg="light"
          expand={expand}
          sticky="bottom"
        >
          <Container fluid>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              className="ms-auto"
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header
                aria-label="Hide"
                closeButton
              ></Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-around flex-grow-1 pe-3">
                  <LogoutButton loggedIn={props.loggedIn} />
                  {props.loggedIn ? <LoggedInUser /> : null}
                  <Nav className="justify-content-end">
                    <Nav.Link
                      className="footer-link"
                      href="https://www.fema.gov/ipaws"
                    >
                      IPAWS
                    </Nav.Link>
                    <Nav.Link className="footer-link" href={contact}>
                      Contact
                    </Nav.Link>
                    <Nav.Link className="footer-link" href={userguide}>
                      User Guide
                    </Nav.Link>
                  </Nav>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Layout;
