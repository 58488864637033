import { useState, useContext } from "react";
import {
  Card,
  Collapse,
  Container,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import Signup from "../components/appComponents/login/Signup";
import Login from "../components/appComponents/login/Login";
import AppPage from "./AppPage";
import { AccountContext } from "../components/universal/Account";

const LoginPage = () => {
  const [openLogin, setOpenLogin] = useState(true);
  const [openSignup, setOpenSignup] = useState(false);

  const [loggedIn] = useOutletContext();
  const { isMfaRequired } = useContext(AccountContext);

  const handleLogin = () => {
    setOpenLogin(true);
    setOpenSignup(false);
  };

  const handleSignup = () => {
    setOpenSignup(true);
    setOpenLogin(false);
  };

  return (
    <>
      {loggedIn === false ? (
        <Container fluid>
          <Card className="login-card">
            {!isMfaRequired ? (
              <ButtonGroup size="lg" className="mb-2">
                <Button
                  variant="primary"
                  onClick={() => handleLogin()}
                  aria-controls="example-collapse-text"
                  aria-expanded={openLogin}
                >
                  Login
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleSignup()}
                  aria-controls="example-collapse-text"
                  aria-expanded={openSignup}
                >
                  Signup
                </Button>
              </ButtonGroup>
            ) : null}
            <Collapse in={openLogin}>
              <div id="Login-collapse-text">
                <Login />
              </div>
            </Collapse>
            <Collapse in={openSignup}>
              <div id="SignUp-collapse-text">
                <Signup />
              </div>
            </Collapse>
          </Card>
        </Container>
      ) : loggedIn === true ? (
        <AppPage />
      ) : null}
    </>
  );
};

export default LoginPage;
