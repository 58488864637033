import { useContext, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { AccountContext } from '../components/universal/Account';

function MFAPage(props) {
    const { getSession } = useContext(AccountContext);
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);

    const handleCodeSubmit = async () => {
        const session = await getSession();
        if (session) {
            session.user.sendMFACode(code, session, {
                onSuccess: function (result) {
                    // console.log('access token + ' + result.getAccessToken().getJwtToken());
                    // Navigate to the next page here
                },
                onFailure: function(err) {
                    console.error(err);
                    setError(err.message);
                }
            });
        } else {
            console.error('No session found');
        }
    };

    return (
        <div>
            <label>Enter the MFA code</label>
            <br />
            <input type="number" value={code} onChange={(e) => setCode(e.target.value)} autofill='one-time-code' />
            <button onClick={handleCodeSubmit}>Submit</button>
            {error && <p>{error}</p>}
        </div>
    );
}

export default MFAPage;