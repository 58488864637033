import React from "react";
import { useLocation } from 'react-router-dom';
import Retool from "react-retool";
import '../../../App.css';
import LoggedOut from "../../universal/LoggedOut";
import { useOutletContext } from "react-router-dom";


const MessageViewerStg = () => {

  const { hash } = useLocation();

  const retoolURL = "https://ipaws-staging-retool.aws.fema.gov/embedded/public/9147d09d-6f19-4687-a70e-55ca7297578f" + hash

  const [loggedIn] = useOutletContext();

  return <>
    {loggedIn ?
      <div className="RetoolApp" >
        <div className="retool-box">
          <Retool url={retoolURL} class="responsive-iframe" />
        </div>
      </div>
      :
      loggedIn === false ? (
        <LoggedOut />
      ) : null
    }
  </>;
};




export default MessageViewerStg
