import React from "react";

function Test() {
  return (
    <div className="container">
      <h1 className="text-center jumbotron">
        This page is reserved for internal testing only.
      </h1>

      <br />
      <br />
      <br />
      <br />

      <h3>Begin testing elements below:</h3>

      <br />

      <hr />

      <br />

      <hr />

      <h3>End testing elements.</h3>
    </div>
  );
}

export default Test;
