import React, { useState, useContext } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "../../universal/Account";
import { Button, Form, Stack, Alert } from "react-bootstrap";
import { AttributeContext } from "../../universal/Attributes.js";

// eslint-disable-next-line
export default () => {

  // Wrong Password Alerts
  const [PasswordAlert, setPasswordAlert] = useState(false);
  const [PasswordAlertMsg, setPasswordAlertMsg] = useState("");
  const [PasswordAlertMsgSubtitle, setPasswordAlertMsgSubtitle] =
    useState("");
  const [AlertType, setAlertType] = useState("danger");

  // new Staging Flag
  const [newStagingFlag, setNewStagingFlag] = useState("0");

  const { getSession } = useContext(AccountContext);
  const { setStagingFlag, stagingFlag } = useContext(AttributeContext);

  const onSubmit = (event) => {
    event.preventDefault();

    getSession().then(({ user }) => {
          const attributes = [
            new CognitoUserAttribute({
              Name: "custom:staging",
              Value: newStagingFlag,
            }),
          ];

          user.updateAttributes(attributes, (err, results) => {
            if (err) {
              // console.error(err);
              setPasswordAlert(true);
              setAlertType("danger");
              setPasswordAlertMsg("Error Changing Staging Access");
              setPasswordAlertMsgSubtitle(err.message);
            } else {
              // console.log("Flag Value(else) : " + stagingFlag);
              // console.log(results);
              setPasswordAlert(true);
              setAlertType("success");
              setPasswordAlertMsg(
                "Staging Access " +
                  (newStagingFlag === "1" ? "Enabled" : "Disabled") +
                  "!"
              );
              setPasswordAlertMsgSubtitle(
                results.substring(0, 1) + results.substring(1).toLowerCase()
              );
              setStagingFlag(newStagingFlag);
            }
          });
        })
        .catch((err) => {
          console.error("Catch: " + err);
          setPasswordAlert(true);
          setPasswordAlertMsg(err.message);
          setPasswordAlertMsgSubtitle("Please try again.");
        });
  };

  return (
    <div>
      <Form
        className="cogid-change-form"
        noValidate
        onSubmit={onSubmit}
        validated={true}
      >
        <Stack gap={3}>
          <h5>IPAWS Alerting Authority ATP version: 1.2.6.7</h5>
          <hr />
        </Stack>
        {/* Input Field Stack */}
        <Stack gap={3}>
          <Form.Group className="mb-3" controlId="formBasicflagChange">
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label="View Staging Applications"
              // onChange={(event) => console.log(event.target.checked)}
              onChange={(event) =>
                setNewStagingFlag(event.target.checked === true ? "1" : "0")
              }
              defaultChecked={stagingFlag === "1" ? true : false}
            />
            <Form.Text id="flagHelpBlock" muted>
              This feature is intended for use by users who need access to the
              IPAWS TDL environment. If you are unsure if you need access to
              this environment, you most likely do not.
            </Form.Text>
          </Form.Group>
          </Stack>

          { stagingFlag !== newStagingFlag ? <>

        <Stack gap={3}>
          <Form.Group>
            <Button
              variant={false ? "warning" : "success"}
              type="submit"
              disabled={false}
            >
              Save Changes
            </Button>
          </Form.Group>
          <Alert
            className="PasswordAlert"
            show={PasswordAlert}
            onClose={() => setPasswordAlert(false)}
            variant={AlertType}
            dismissible
          >
            <Alert.Heading>{PasswordAlertMsg}</Alert.Heading>
            <p>{PasswordAlertMsgSubtitle}</p>
          </Alert>
        </Stack> 
        </>
        : "" }
      </Form>
    </div>
  );
};
