import React, {useContext} from "react";
import { AttributeContext } from "./Attributes";

const LoggedInUser = (props) => {

  const Attributes = useContext(AttributeContext);
  const { email} = Attributes;

    return <div>
        
            < div className="d-flex">
                <h5 className="logged-in-user">
                    Current User: {email}
                </h5>
            </div> 
            
    </div>;
};

export default LoggedInUser 