import { useState, useContext } from "react";
import { Button, Form, Stack, FloatingLabel, Alert, FormCheck } from "react-bootstrap";
import QRCode from "qrcode.react";
import Pool from "../../../UserPool";
import { AttributeContext } from "../../universal/Attributes";
import { AccountContext } from "../../universal/Account";
import { useNavigate } from "react-router-dom";

function MfaSetupPage() {
  const [showSetUpButton, setShowSetUpButton] = useState(true);
  const [showQrCode, setShowQrCode] = useState(false);
  const [MFACode, setMFACode] = useState("");
  const [MFAValidated, setMFAValidated] = useState(false);
  const [MFAValidatedMsg, setMFAValidatedMsg] = useState("");
  const [MFAAlert, setMFAAlert] = useState(false);
  const [AlertType, setAlertType] = useState("danger");
  const [MFAAlertMsg, setMFAAlertMsg] = useState("");
  const [MFAAlertMsgSubtitle, setMFAAlertMsgSubtitle] = useState("");
  const [otpauth, setOtpauth] = useState("");
  const [showMFASecretCode, setShowMFASecretCode] = useState(false);
  const [MFACheckbox, setMFACheckbox] = useState(false);
  const [confirmShowMFASecretCode, setConfirmShowMFASecretCode] = useState(false);

  const { email, setLoggedIn } = useContext(AttributeContext);
  const { getSession, logout } = useContext(AccountContext);

  const navigate = useNavigate();

  const user = Pool.getCurrentUser();

  const getValidSession = async () => {
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.error(err);
          return;
        }

        if (session.isValid()) {
          setupNewMfaDevice(user);
        } else {
          user.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
              console.error(err);
              return;
            }

            if (session.isValid()) {
              setupNewMfaDevice(user);
            } else {
              console.error("Failed to refresh session");
            }
          });
        }
      });
    } else {
      console.error("No user is currently signed in");
    }
  };

  const setupNewMfaDevice = async () => {
    // const user = Pool.getCurrentUser();

    if (!user) {
      console.error("No user is currently signed in");
      return;
    }

    user.associateSoftwareToken({
      associateSecretCode: (secretCode) => {
        setOtpauth(
          `otpauth://totp/IPAWS:AAATP - ${email}?secret=${secretCode}&issuer=FEMA`
        );
        setShowQrCode(true);
        setShowSetUpButton(false);
      },
      onSuccess: (session) => {
        // console.log("MFA device associated successfully");
        // The rest of your code...
      },
      onFailure: (err) => {
        console.error("Failed to associate MFA device", err);
        setMFAAlert(true);
        setMFAAlertMsg(err.message);
        setMFAAlertMsgSubtitle("Please try again.");
      },
    });
  };

  const submitMFACode = () => {
    if (!user) {
      console.error("No user is currently signed in");
      return;
    }

    user.getSession((err, session) => {
      if (err) {
        console.error(err);
        return;
      }

      if (!session.isValid()) {
        user.refreshSession(session.getRefreshToken(), (err, session) => {
          if (err) {
            console.error(err);
            return;
          }
        });
      }

      user.verifySoftwareToken(MFACode, "MFA device", {
        onSuccess: (session) => {
          // console.log("MFA code verified successfully");
          // console.log(session);
          user.setUserMfaPreference(
            {
              Enabled: false,
              PreferredMfa: false
            },
            {
              Enabled: true,
              PreferredMfa: true
            },
            (err, result) => {
              if (err) {
                console.error("Failed to set MFA preference", err);
              } else {
                getSession().then(() => {
                  setLoggedIn(false);
                  logout();
                  window.location.hash = "";
                  navigate(0);
                });
                
                // console.log("MFA preference set successfully");
                // console.log(result);
                // setAlertType("success");
                // setMFAAlert(true);
                // setMFAAlertMsg("MFA device has been set up!");
                // setMFAAlertMsgSubtitle("You may now log in with MFA.");
              }
            }
          );
        },
        onFailure: (err) => {
          console.error("Failed to verify MFA code", err);
          setAlertType("danger");
          setMFAAlert(true);
          setMFAAlertMsg("Error setting up MFA device");
          setMFAAlertMsgSubtitle(err.message);
        },
      });
    });
  };

  // This uses RegEx to validate the COG ID format. it then sets the state of the COG ID value and the COG ID validation.
  const MFAValidation = (code) => {
    setMFACode(code);
    var re = {
      length: /(?=^[0-9]{6}$)/,
    };
    // test if the code has anything but numbers in it
    var re_alpha = /(?=.*[a-zA-Z])/;
    if (code === "") {
      setMFAValidated(false);
      setMFAValidatedMsg("");
    }
    else if (re_alpha.test(code)) {
      setMFAValidated(false);
      setMFAValidatedMsg("MFA code must be numbers only");
    }
    else if (code.length < 6) {
      setMFAValidated(false);
      setMFAValidatedMsg("MFA code must be 6 digits");
    }
    else if (code.length > 6) {
      setMFAValidated(false);
      setMFAValidatedMsg("MFA code must be 6 digits pls");
    }
    else {
      setMFAValidated(re.length.test(code));
      setMFAValidatedMsg(re.length.test(code) ? "" : "");
    }


  };

  const onSubmit = (event) => {
    event.preventDefault();
    submitMFACode();
  };

  return (
    <div className="MFAChangeDiv">
      {showSetUpButton && (
        <Button onClick={getValidSession} variant="success">Set up MFA device</Button>
      )}

      {showQrCode && (
        <Form className="mfa-change-form" onSubmit={onSubmit}>
          <Stack gap={3}>
            <Form.Group className="mb-3" controlId="formMFAChange">
              <div style={{ marginTop: "10px" }}>
              {!showMFASecretCode && 
                <>
                  <QRCode value={otpauth} />
                  <p>
                    You must have an MFA app installed on your phone and scan this code within that app, not your phones camera app. We support software MFA, which includes mobile apps such as Authy, Google Authenicator, and Microsoft Authenticator.
                  </p>
                </>
              }
                <br />
                <Button
                  style={{ marginTop: "10px"}}
                  onClick={(event) => {
                    setShowMFASecretCode(!showMFASecretCode)
                    setConfirmShowMFASecretCode(true)
                    setMFACheckbox(false)
                  }
                  }
                  variant={!showMFASecretCode ? "secondary" : "success"}
                  size="sm"
                >
                  {!showMFASecretCode ? "Request Secret Code" : "Cancel Secret Code Request"}
                </Button>
                <br />
                {!showMFASecretCode && 
                  <Form.Text muted>
                    Only use this code if the MFA app you are using is having trouble reading the QR code.
                  </Form.Text>
                }
                {showMFASecretCode && (<>
                <p style={{ marginTop: "10px"}}>
                  <strong>
                    You only need the secret code if your phone camera WILL NOT SCAN the QR code. 
                    Ensure you have downloaded an authenticator app like Microsoft Authenticator, Google Authenticator or Authy and add your account by scanning the QR code within that app, not your phones camera app.
                  </strong>
                </p>
                {confirmShowMFASecretCode ? 
                <>
                <FormCheck
                  type="checkbox"
                  label="I have read the instructions above and understand that I only need the secret code if my phone camera will not scan the QR code."
                  style={{ marginTop: "10px" }}
                  onChange={(event) => {
                    setMFACheckbox(!MFACheckbox)
                  }}
                  />
                  <Button
                  style={{ marginTop: "10px" }}
                  onClick={(event) => {
                    setConfirmShowMFASecretCode(!confirmShowMFASecretCode)
                  }}
                  disabled={!MFACheckbox}
                  variant={!MFACheckbox ? "secondary" : "danger"}
                  size="sm"
                >
                  Proceed
                </Button>
                </> : (
                  <p>
                    <strong>Secret Code:</strong> {otpauth.split("secret=")[1].split("&")[0]}
                  </p>
                )}
                </>
                )}
              </div>
              <FloatingLabel
                controlId="floatingInputMFAChange"
                label="Scan the QR Code above with your MFA App and enter the 6 digit code here"
                className="mb-2"
              >
                <Form.Control
                  // type="number"
                  placeholder="Enter your MFA code"
                  value={MFACode}
                  onChange={(event) => MFAValidation(event.target.value)}
                  style={{ height: "50px", marginTop: "10px" }}
                  maxLength="6"
                />
                <Form.Text muted>
                  {MFAValidatedMsg}
                </Form.Text>
              </FloatingLabel>
              { MFAValidated &&
              <Button
                style={{ marginTop: "10px" }}
                // onClick={MFAValidation}
                variant={!MFAValidated ? "warning" : "success"}
                type="submit"
                disabled={!MFAValidated}
              >
                Submit MFA Code
              </Button>
            }
            </Form.Group>
            <Alert
              className="MFAAlert"
              show={MFAAlert}
              onClose={() => setMFAAlert(false)}
              variant={AlertType}
              dismissible
            >
              <Alert.Heading>{MFAAlertMsg}</Alert.Heading>
              <p>{MFAAlertMsgSubtitle}</p>
            </Alert>
            { MFAValidated &&
            <Alert
              className="MFAAlert"
              show='true'
              variant='warning'
              >
              <Alert.Heading>Important</Alert.Heading>
              <p>After setting up your MFA device, you will be logged out of the system. Please log back in with your MFA device to continue.</p>
            </Alert>
              }

          </Stack>
        </Form>
      )}
    </div>
  );
}

export default MfaSetupPage;
