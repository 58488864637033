import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { AccountContext } from "./Account";
import { Button } from "react-bootstrap";
// import { useOutletContext } from "react-router-dom";

const LogoutButton = ({loggedIn, expanded}) => {

  const { getSession, logout } = useContext(AccountContext);

  const navigate = useNavigate();



  const logoutButton = () => {
    getSession().then(() => {
      logout();
      window.location.hash = "";
      navigate(0);
    });
  };

  return (
    <div>
      {loggedIn ? (
        <div>
          <div className="d-flex justify-content-around">
            <Button onClick={logoutButton} variant="danger" size="md">
              Log Out
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default LogoutButton;
