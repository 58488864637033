import React, { useContext } from "react";
import "../../../App.css";
import { Card, Button, Col, Row } from "react-bootstrap";
import mvImg from "../../../img/undraw_post_re_mtr4.svg";
import mddImg from "../../../img/undraw_asset_selection_re_k5fj.svg";
import LoggedOut from "../../universal/LoggedOut";
import Env from "../../../env.js";
import { useOutletContext } from "react-router-dom";
import { AttributeContext } from "../../universal/Attributes.js";

const AppSelection = () => {
  const Attributes = useContext(AttributeContext);
  const { stagingFlag } = Attributes;

  const [loggedIn] = useOutletContext();

  const slug = Env.SLUG;
  const mv = slug + "messageviewer";
  const mvstg = slug + "messageviewerstg";
  const mdd = slug + "mdd";

  return (
    <>
      {loggedIn ? (
        <>
          <div className="appContainer">
            <Row xs={1} md={2} className="g-1">
              <Col key={"a"}>
                {["Light"].map((variant) => (
                  <Card
                    bg={variant.toLowerCase()}
                    key={variant}
                    text={variant.toLowerCase() === "light" ? "dark" : "white"}
                    style={{
                      width: "80%",
                      height: "auto",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "10%",
                    }}
                    className="mb-2 text-center"
                  >
                    <Card.Img
                      className="app-img"
                      variant="top"
                      src={mvImg}
                      style={{
                        width: "18rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      title="Message Viewer"
                    />
                    <Card.Header>
                      <strong className="app-title">Message Viewer</strong>
                    </Card.Header>
                    <Card.Body>
                      <Button
                        className="mvButtonSize"
                        variant="primary"
                        href={mv}
                      >
                        Lab Message Viewer
                      </Button>{" "}
                      <br></br>
                      {stagingFlag === "1" ? (
                        <Button
                          className="mvButtonSize"
                          variant="primary"
                          href={mvstg}
                        >
                          Staging Message Viewer
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                ))}
              </Col>
              <Col key={"b"}>
                {["Light"].map((variant) => (
                  <Card
                    bg={variant.toLowerCase()}
                    key={variant}
                    text={variant.toLowerCase() === "light" ? "dark" : "white"}
                    style={{
                      width: "80%",
                      height: "auto",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "10%",
                    }}
                    className="mb-2 text-center"
                  >
                    <Card.Img
                      className="app-img"
                      variant="top"
                      src={mddImg}
                      style={{
                        width: "18rem",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                      }}
                      title="Message Design Dashboard"
                    />
                    <Card.Header>
                      <strong className="app-title">
                        Message Design Dashboard
                      </strong>
                    </Card.Header>
                    <Card.Body>
                      {/* <Card.Text>
                      </Card.Text> */}
                      <Button variant="primary" href={mdd}>
                        Go To MDD
                      </Button>
                    </Card.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </div>
        </>
      ) : loggedIn === false ? (
        <LoggedOut />
      ) : null}
    </>
  );
};

export default AppSelection;
