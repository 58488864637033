import ReactDOM from "react-dom/client";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ProtectedRoute from "./components/tools/ProtectedRoute.js";
//import reportWebVitals from './reportWebVitals';

//system components
import NoPage from "./pages/NoPage";
import Contact from "./pages/Contact";
import { Account } from "./components/universal/Account";
import MFAPage from "./pages/MFAPage.js";

//user made components
import LoginPage from './pages/LoginPage';
import MessageViewerPage from './pages/MessageViewerPage';
import MessageViewerStgPage from "./pages/MessageViewerStgPage";
import MDD from "./pages/MDD";
import TestPage from "./pages/testPage";
import UserDashboardPage from "./pages/UserDashboardPage";
import AppPage from "./pages/AppPage";

//env variables
import Env from "./env.js"



//import attribute context
import { Attribute } from "./components/universal/Attributes";

export default function App(props) {

  

  // const Attributes = useContext(AttributeContext);
  // const { mfa } = Attributes;

  const slug = Env.SLUG;
  const apppage = slug + "app";
  const mdd = slug + "mdd";
  const mv = slug + "messageviewer";
  const login = slug + "login";
  const dashboard = slug + "dashboard";
  const test = slug + "test";
  const contact = slug + "contact";
  const all = slug + "*";
  const mvstg = slug+"messageviewerstg";
  const mfapage = slug+"mfa";
  const app = slug + "app";
  const atp = slug + "atp";

  return (
    <BrowserRouter>
      <Routes>
        <Route path={Env.SLUG} element={<Account />}>
          <Route index element={<LoginPage loggedIn={props.loggedIn} />} />
          <Route path={apppage} element={<AppPage />} />
          <Route path={mdd} element={<MDD />} />
          <Route path={mv} element={<MessageViewerPage />} />
          <Route path={mvstg} element={<MessageViewerStgPage />} />
          <Route path={login} element={<LoginPage />} />
          <Route path={dashboard} element={<UserDashboardPage />} />
          <Route path={test} element={<TestPage />} />
          <Route path={contact} element={<Contact />} />
          <Route path={all} element={<NoPage />} />
          <Route path={mfapage} element={<MFAPage />} />
          <Route path={app} element = {<LoginPage />} />
          <Route path={atp} element = {<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Attribute>
    <App />
  </Attribute>
  );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
